var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.itemsLinks},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("chevron_right")])]},proxy:true}])})],1),_c('v-col',{staticClass:"ma-0",attrs:{"cols":"12","lg":"12"}},[_c('data-table',{ref:"tablaFormatos",attrs:{"tableName":'Documentos disponibles',"url":_vm.url,"columns":_vm.columns,"filters":_vm.filters,"showAdvancedFilters":false,"perPage":[10,25,50,100],"loading":_vm.isLoading},on:{"loadModalData":_vm.loadModalData,"setFilters":_vm.setFilters},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',{staticClass:"tblPrincipal"},[_vm._v(" "+_vm._s(item.nombre)+" ")])]),_c('td',[_c('div',{staticClass:"tblOpciones align-center"},[_c('v-switch',{attrs:{"label":"Aplica"},on:{"change":function($event){return _vm.updateDocumento(item)}},model:{value:(item.aplica),callback:function ($$v) {_vm.$set(item, "aplica", $$v)},expression:"item.aplica"}}),_c('v-menu',{staticClass:"elevation-0 d-inline",attrs:{"button":"","right":"","content-class":"mt-0 menuClassOpciones"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",attrs:{"icon":"","fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._l((attrs),function(item,valor){return [(valor == 'aria-expanded' &&  item == 'false')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#828282"}},[_vm._v("more_vert")]):_vm._e(),(valor == 'aria-expanded' &&  item == 'true')?_c('v-icon',{key:valor,staticClass:"pl-2",attrs:{"color":"#2DB9D1"}},[_vm._v("more_vert")]):_vm._e()]})],2)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.abrirModalAsignar(item)}}},[_c('v-list-item-title',[_vm._v("Asignar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-list-item-title',[_vm._v("Eliminar")])],1)],1)],1)],1)])])}),0)}}])},[_c('template',{slot:"filters"})],2)],1)],1)],1),_c('v-dialog',{staticClass:"borde-card",attrs:{"persistent":"","max-width":"650px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialogAsignar),callback:function ($$v) {_vm.dialogAsignar=$$v},expression:"dialogAsignar"}},[_c('v-card',{staticClass:"borde-card"},[_c('v-card-title',{staticClass:"card_titulo padding_izquierdo padding_derecho"},[_c('div',{staticClass:"headerModalStart"},[_c('h2',{staticClass:"titleModal_700_18"},[_vm._v(_vm._s(_vm.tituloModal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('v-container',{attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-row',{staticClass:"my-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('MultipleAutocomplete',{ref:"multSucursal",attrs:{"outlined":"","items":_vm.empleados,"valorDefecto":_vm.empleados_seleccionados,"item_text":"nombre_completo","label":"Todos los empleados"},on:{"dataMultiple":function (data){ return _vm.empleados_seleccionados = data; }}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('button',{staticClass:"btnCerrar",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"loading":_vm.isSaving},on:{"click":function($event){return _vm.asignar()}}},[_vm._v(" Asignar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }